#showcase{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    position: relative;
    z-index: 1020;

    a{
        color: white !important;
    }

    #anchor{
        position: absolute;
        display: block;
        height: 70px;
        width: 100%;
        bottom: 0;
    }
}