#parallax{
    padding: 0;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);

    .parallax-header{
        background: #000;
        position: relative;
        height: 650px;
        overflow: hidden;
        img{
            margin-top: -300px;
        }
        h3{
            position: absolute;
            bottom: 50px;
            color: white;
        }
        i{
            position: absolute;
            bottom: 100px;
            color: white;
        }
    }
}