#subFeature{
    padding: 0;
    background-color:white;
    color: black;

    .content-info{
        box-shadow: $box-shadow;
        border: 1px solid white;
        background-color: $secondary;
        @include media-breakpoint-only(xs) { margin: 1rem 0.5rem; padding: 1rem 0.75rem; }
        @include media-breakpoint-only(sm) { margin: 1rem 0.5rem; padding: 1rem 0.75rem; }
        @include media-breakpoint-only(md) { margin: 5rem 3rem; padding: 3rem 2rem; }
        @include media-breakpoint-only(lg) { margin: 5rem 3rem; padding: 3rem 2rem; }
        @include media-breakpoint-only(xl) { margin: 5rem 3rem; padding: 3rem 2rem;}
        @include media-breakpoint-only(xxl) { margin: 5rem 3rem; padding: 3rem 2rem; }
        .col-title{
            h3{
                i{
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 4rem;
                }
                font-size: 2rem;
            }
        }
        .col-text{
            p{
                font-size: 4rem;
            }
        }

        @include media-breakpoint-only(xs) {
        .col-text{
            p{
                font-size: 2.5rem;
            }
        }
        }

        @include media-breakpoint-only(sm) {
        .col-text{
            p{
                font-size: 2.5rem;
            }
        }
        }
    }

    .galerie-chambre-funeraire{
        padding: 3rem 0 5rem 0;
        .col{
            a{
                box-shadow: $box-shadow;
                display: block;
                border: 1px solid $white;
                cursor: pointer;
            }
        }
    }

}