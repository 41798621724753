@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

body{
    font-family: "Roboto";
}

.bi-icon {
    font-size: 2rem; 
    color: cornflowerblue;
}

/* Content Text */
.content-text{
    .col-text{
        h3, p {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }
}
