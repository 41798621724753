#subMain{
    padding: ($content-padding * 2) $content-padding;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);

    .feature-accueil{
        .col{
            text-align: center;
            i{
                display: block;
                margin: 1rem 0 2rem 0;
                color: $primary;
            }
            p{
                font-size: 1.5rem;
            }
        }
    }
}