header{
    padding: 0;
    color: white;
    z-index: 1020;
    a{
        color: white !important;
    }

    #navbar-top-mobile{
        background-color: #c5beb9;
    }

    .navbar{
        z-index: 1030;
        .bi{
            color: white;
        }
        .nav-item{
            font-family: 'Archivo Black', sans-serif;
            @include media-breakpoint-only(xs) { a { font-size: 1.2rem; } }
            @include media-breakpoint-only(sm) { a { font-size: 1.2rem; } }
            @include media-breakpoint-only(md) { a { font-size: 1rem; } }
            @include media-breakpoint-only(lg) { a { font-size: 1rem; } }
            @include media-breakpoint-only(xl) { a { font-size: 1.2rem; } }
            @include media-breakpoint-only(xxl) { a { font-size: 1.2rem; } }

            .nav-link{
                text-shadow: 2px 2px 2px black;
                transition: 1.5s;
            }

            .nav-link:hover{
                border-bottom: 2px solid white; 
             }
        }

        .nav-item.active{
            a{
               border-bottom: 2px solid white; 
            }
        }
    }

    .no-move {
	    background-color: transparent;
	    transition: 1.5s;
	}
    
	.move-up, .move-down {
	    background-color: #c5beb9;
	    transition: 1.5s;

        .nav-link{
            text-shadow: none !important;
            transition: 1.5s !important;
        }
	}

    .move-up{
        margin-top: -50px;
        transition: .7s;
    }

    .move-down{
        margin-top: 0;
        transition: .7s;
    }

}