#feature{
    padding: ($content-padding * 2) $content-padding;
    background-color: $primary;
    color: white;

    .feature-accueil{
        h3{
            min-height: 2rem;
        }
    }

    .feature-services{
        .col{
            text-align: center;
            border: 1px solid $white;
            i{
                display: block;
                margin: 1rem 0 2rem 0;
                color: $white;
            }
            p{
                font-size: 1.5rem;
            }
        }
    }

    .content-info{
        margin: 5rem;
        border-bottom: 1px solid $white;
        .col{
            text-align: center;
            i{
                display: block;
                margin: 1rem 0 ;
                color: $white;
                font-size: 3rem;
            }
            p{
                font-size: 1.3rem;
            }
        }
    }

    .profile-situation{
        color: black;
        h2{
            color: white;
        }
        .imgs{
            img{
                border: 1px solid $white;
                box-shadow: $box-shadow;
            }
        }
        .card-body{
            .card-title{
                padding: 0.5rem;
                background: $secondary;
            }
            p{
                margin-bottom: 0.5rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                
            }
        }
        
    }

    .galerie{
        padding: 3rem 0 5rem 0;
        .col{
            a{
                box-shadow: $box-shadow;
                display: block;
                border: 1px solid $white;
                cursor: pointer;
            }
        }
    }

}